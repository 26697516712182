
import { defineComponent, onMounted } from 'vue';
import axios from "axios";
import sample1 from '../components/sample1.vue';
import sample2 from '../components/sample2.vue';

export default defineComponent({
  name: 'main',
  components: {
    sample1,
    sample2
  },
  
  data: function(){
    return{
      Title:"",
      Body:"",
      sample1:false,
      sample2:false
    }
  },

  methods: {
    async test2(){
      if(this.Title != "" && this.Body != ""){
        try {
          // 通知データを設定
          const payload = {
                title: this.Title,
                body: this.Body,
          };

          const cloudFunctionURL = 'https://us-central1-dapopush.cloudfunctions.net/pushSubmitFromURL'; 

          const response = await axios.post(cloudFunctionURL, payload);
          const responseData = response.data;

          if(responseData == "プッシュ通知が正常に送信されました"){
            alert('通知が正常に送信されました')
            this.Title = ""
            this.Body = ""
          }
        } catch (error) {
          console.error('プッシュ通知の送信中にエラーが発生しました', error);
        }
      } else {
        alert('タイトルまたは本文が未入力です')
      }
    },

    sample1open(){
      this.sample1 = true
    },

    sample2open(){
      this.sample2 = true
    },

    sample1close(Value:boolean){
      this.sample1 = Value
    },

    sample2close(Value:boolean){
      this.sample2 = Value
    }
  },

  
  mounted(){

    const setFillHeight = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', setFillHeight);

    setFillHeight();
  }
});
